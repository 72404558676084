@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600&display=swap');


body {
  margin: 0;
  font-family: 'Prompt', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}

 @media screen and (min-device-width: 1200px) {
      body {
        font-size: 20px;
        line-height: 30px;
      }
  }